import { PubSub } from "@aws-amplify/pubsub"
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react"
import "@aws-amplify/ui-react/styles.css" // default theme
import { CssBaseline, Link } from "@mui/material"
import Box from "@mui/material/Box"
import { ThemeProvider } from "@mui/material/styles"
import { Amplify } from "aws-amplify"
import { theme } from "./Theme"
import awsExports from "./aws-exports"
import neusoftLogo from "./images/Neusoft_White.svg"
import RoutingPage from "./pages/RoutingPage"

Amplify.configure(awsExports)
// PubSub.configure(awsExports) // silences error, but we don't use it, unclear why it is needed

export default function RoutingApp() {
   const { user } = useAuthenticator(context => [context.user])
   let logo
   let sx
   let components

   if (user === undefined) {
      logo = <Box id="login-header" sx={{ maxWidth: "100vw" }} component="img" src={neusoftLogo} alt="Neusoft" />

      sx = {
         height: "100dvh",
         backgroundColor: "primary.main",
         display: "flex",
         alignItems: "center",
         flexDirection: "row",
         justifyContent: "center",
         flexWrap: "wrap",
         color: "lightgrey",
      }

      components = {
         Footer() {
            return (
               <Box
                  id="login-footer"
                  sx={{
                     width: "100%",
                     textAlign: "center",
                  }}
               >
                  {getCopyrightString()} |{" "}
                  <Link color="inherit" href="/legal/onecorego/privacy">
                     Privacy Policy
                  </Link>
               </Box>
            )
         },
      }
   } else {
      sx = {
         minHeight: "100dvh",
         display: "flex",
      }
   }

   return (
      <ThemeProvider theme={theme}>
         <Box sx={sx}>
            {logo}
            <Authenticator components={components}>
               {({ signOut, user }) => (
                  <ThemeProvider theme={theme}>
                     <CssBaseline enableColorScheme />
                     <Box
                        sx={{
                           width: `100%`,
                           display: "flex",
                           justifyContent: "flex-start",
                           flexDirection: "column",
                           minHeight: "100%",
                           flexGrow: 1,
                        }}
                     >
                        <RoutingPage />
                     </Box>
                  </ThemeProvider>
               )}
            </Authenticator>
         </Box>
      </ThemeProvider>
   )
}

function getCopyrightString() {
   const year = new Date().getFullYear()
   const rootYear = 2020
   if (year === rootYear) {
      return `Copyright © ${rootYear} Neusoft Corporation.`
   } else {
      return `Copyright © ${rootYear} - ${year} Neusoft Corporation.`
   }
}
