import { Authenticator } from "@aws-amplify/ui-react"
import { StrictMode } from "react"
import { createRoot } from "react-dom/client"
import RoutingApp from "./RoutingApp"

import "./i18n"
import "@fontsource/exo-2"
import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"
import "./index.css"

const rootDiv = document.getElementById("root")

if (rootDiv !== null) {
   createRoot(rootDiv).render(
      <StrictMode>
         <Authenticator.Provider>
            <RoutingApp />
         </Authenticator.Provider>
      </StrictMode>,
   )
} else {
   console.error("root div missing")
}
